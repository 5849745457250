.header-containerOLD {
    /* background-image: linear-gradient(to right, #085dad, #6599ff); */
    /* background-image: linear-gradient(to right, #4A4A4A, #000); */
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(12px + 2vmin);
    color: white;
    padding-top: 180px;
    max-width:960px;
    /* text-align: center; */
  }

  .header-container {
    max-width:960px;
    margin: 0 auto;
  }

.header-logo {
  /* height: 15vmin; */
  max-height:16vmin;
  /* pointer-events: none; */
  z-index: 1000;
  /* margin-left: -20px */
  /* float: left; */
  /* display: block; */
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 50%; */
}

.header-text {
    font-size: calc(14px + 3vmin);
    margin-top: 40px;
    text-align: center;
  color: white;

  }