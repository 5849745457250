@font-face {
  font-family: "tomatogrotesk";
  src: url("fonts/TomatoGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "tomatogrotesk";
  src: url("fonts/TomatoGrotesk-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

body {
  background-image: linear-gradient(to right, #4a4a4a, #000);
}

p {
  margin: 5px 0;
  /* font-family: Montserrat, sans-serif; */
}

.App {
  /* text-align: center; */
}

.App-header {
  /* background-image: linear-gradient(to right, #085dad, #6599ff); */
  /* background-image: linear-gradient(to right, #4a4a4a, #000); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(12px + 2vmin);
  color: white;
  padding-top: 180px;
  text-align: center;
}

.App-header img {
  /* text-align: center; */
  
  /* border: 1px solid red; */

}

.Home-text {
  text-align: center;
}

.background {
  /* background-image: linear-gradient(to right, #4a4a4a, #000); */
  position: absolute;
  top: 0;
  min-height: 100vh;
  width: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 20vh;
}

.center-content-justify-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* background-color: red; */
  /* min-height: 100vh; */
}

.form {
  display: flex;
  flex-direction: column;
  /* padding: 50px 20px; */
}

.content-width {
  min-width: 50vw;
  max-width: 350px;
}

.form-container {
  /* padding: 20px 20px; */
  /* background-color: white; */
  width: 80%;
  min-width: 50vw;
  max-width: 350px;
  border-radius: 10px;
  /* margin-bottom: 200px; */
  /* background-color: blue; */
}

fieldset {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: none;
  margin-bottom: 40px;
  padding: 0px;
  width: 100%;
}

h1 {
  color: rgb(222, 206, 172);
  font-weight: normal;
  font-size: calc(2.2em + 2vw);
  text-align: left;
}

@media screen and (min-width: 844px) {
  h1 {
    text-align: center;
  }
}

h3 {
  color: rgb(222, 206, 172);
  font-weight: normal;
  font-size: calc(1em + 0.5vw);
  text-align: left;
}

.appStoreLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.content-container { 
  margin:0 auto;
  max-width:960px;
  padding-left:10px;
  padding-right:10px;
  /* text-align: center; */
  color: white;
}

.content-container p { 
  line-height: 1.5em;
  margin-bottom:10px;
}

.content-container h3 { 
  text-align: center;
}

a {
  color: white;
}