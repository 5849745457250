
/* .finimize-background { min-height: 100vh; background: url('background/large.jpg'); } */
.finimize-container { margin: 0 auto; width: 40vw; padding: 10px; }
.finimize-container h1.white { font-size: 3em; font-weight: 900; color: white; }
.finimize-container h1.black { font-size: 3em; font-weight: 900; color: white; }

.finimize-container ul, .finimize-container li { color: white; }
.finimize-container li { line-height: 2em; }
.finimize-container p { color: white; margin-bottom: 30px; text-align: center; }
.finimize-container h4 { font-size: 2em; margin: 0px; margin-bottom: 10px; }
.finimize-container h5 { text-transform: uppercase; color: #333; font-weight: 300; margin: 0px; }
.finimize-container h4.white { font-size: 2em; margin: 0px; margin-bottom: 10px; color: #fff; }
.finimize-container h5.white { text-transform: uppercase; color: #fff; font-weight: 300; margin: 0px; }

.finimize-container .strong { font-weight: 900; }
.finimize-container .orange-pill {
    /* display: block;
    width: 100%;
    height: 100%; */
    background: #ffffff;
    background: radial-gradient(circle at left top, rgb(255, 227, 200) 0%, #d39d77 30%, #ac7954 100%);
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 50%;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
.finimize-container .grey-pill {
    /* display: block;
    width: 100%;
    height: 100%; */
    background: #ffffff;
    background: radial-gradient(circle at left top, #ffffff 0%, #e0e0e0 10%, #b3b3b3 70%, #888888 100%);
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.finimize-container .brown-pill {
    background: #ffffff;
    background: radial-gradient(circle at left top, rgb(179, 120, 88) 0%, #ad7a5e 10%, #8e5c41 70%, #693c24 100%);
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
}