.Home-logo {
  height: 25vmin;
  pointer-events: none;
  /* max-width:100%; */
}

.Home-small-print {
  position: absolute;
  bottom: 10px;
  margin-top: 100px;
  font-size: calc(3px + 1.5vmin);
  color: lightgray;
}

.Home-text {
  font-size: calc(14px + 3vmin);
  margin-top: 40px;
}

.App-container {
  text-align: center;
}