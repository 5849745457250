:root {
  --pill-height: 40px; /* Height of the pill */
  --pill-padding: 5px; /* Padding inside the pill */
  --pill-background: #f2f2f2; /* Background color of the pill */
}

* {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.backgroundImage {
  position: absolute;
  bottom: 0;
  right: -20vw;
  /* height: 150vh; */
  min-width: 100vw;
  z-index: -1;
  transform: translateY(40vh);
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  position: relative;
  overflow: hidden;
}

.parallaxContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  object-fit: cover;
  transform: translateY(0);
}

.content {
  z-index: 2;
  height: fit-content;
  width: 100%;
  /* position: absolute; */
  opacity: 1;
  /* top: 100vh; */
}

.heroTextContainer {
  /* background-color: white; */
  height: fit-content;
  width: 100%;
  overflow-wrap: break-word;
  margin-bottom: 200px;
}

.heroBackground {
  background-image: url("../../../assets/cropped_lowres.png");
  background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  /* background-position: 0vw min(-65vw, 40vh); */
  background-repeat: no-repeat;
  /* background-origin: border-box; */
}

.heroText {
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 5vh;
  background-color: white;
  font-size: clamp(1px, 20vw, 160px);
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 600;
  color: black;
  width: 100vw;
  text-align: center; /* Center text */
  mix-blend-mode: screen; /* This makes the cutout text possible */
  /* height: 70vh; */
  height: fit-content;
  text-align: left;
  word-break: keep-all;
  margin: 0;
  box-sizing: border-box;
  /* position: absolute; */
  /* top: 0; */
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 500px;
  max-width: 85%;
  margin:0 auto;
  /* margin-top: 40vh;
  margin-bottom: 30vh; */
  /* position: absolute;
  top: 100vh; */
  /* background-color: blue; */
}

.buttonContainer {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* align-self: center; */
  width: 300px;
  max-width: 85%;
  margin:0 auto;
}

.button {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
  /* padding-top: 10px; */
  /* line-height: 2.6em; */
  /* display: flex;
  align-items: center; */
  background-color: var(--pill-background);
  border-radius: 50px; /* Pill shape */
  /* padding: var(--pill-padding); */
  /* height: var(--pill-height); */
  box-shadow: 12px 5px 19px 5px rgba(12, 12, 12, 0.2);
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  padding: 20px;
  /* height: 68px; */
  line-height: 1em;
  /* border: 1px solid red; */
}

.formContainer p {
  color: white;
}

.refCodeFormContainer {
  margin-top: 40vh;
  margin-bottom: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
}

.joinNowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 40vh;
  margin-bottom: 10vh;
  min-width: 50vw;
  max-width: 350px;
  /* position: absolute;
  top: 100vh; */
  /* background-color: blue; */
}

.joinNowContainer > p {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: white;
}

.whiteText {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  /* line-height: 1.em; */
  padding: 40px;
}

.errorText {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  font-size: 12px;
  color: red;
  font-weight: bold;
  text-align: center;
  /* line-height: 1.em; */
  padding: 40px;
}

.title {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  font-size: 40px;
  font-weight: 600;
  width: 90%;
}

.formWelcome {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  font-size: 44px;
  font-weight: 600;
  width: 90%;
  text-align: center;
}

@media (min-aspect-ratio: 1/1) {
  .formWelcome {
    font-size: 44px;
  }
}

.referralInputContainer {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background-color: var(--pill-background);
  border-radius: 50px; /* Pill shape */
  padding: var(--pill-padding);
  height: var(--pill-height);
}

.referralInput {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  border: none;
  outline: none;
  background: transparent;
  padding: 10px var(--pill-padding) 10px 10px;
  border-radius: 50px;
  flex-grow: 1;
  font-size: calc(var(--pill-height) / 2.5);
}

.referralInputButton {
  background-color: #cb926a;
  height: 100%;
  max-width: calc(var(--pill-height));
  aspect-ratio: 1;
  border-radius: 50%; /* Circular shape */
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.checkboxDivLabel {
  font-size: 16px;
}

.disclaimerContainer {
  background-color: black;
  box-sizing: border-box;
  padding: 20px 20px;
  color: white;
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  font-size: 12px;
}

.disclaimerContainer p {
  text-align: center;
}

.disclaimerContainer > div > p {
  text-align: left;
}

.logoContainer {
  /* position: fixed; */
  width: 30vw;
  max-width: 200px;
  max-height: 15px;
  /* overflow: hidden; */
  height: fit-content;
  z-index: 5;
  padding-left: 5vw;
}

.logoContainer svg {
  
  width: 100%;
}

form > p {
  color: black;
  opacity: 1;
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.contentBackground {
  margin-top: 5vh;
  background-color: rgba(0, 0, 0, 0);
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  height: fit-content;
}

.title {
  font-weight: 600;
  color: white;
}

.disclaimerCrossHair {
  width: 60vw;
  max-width: 20px;
  /* height: 60vw;
  max-height: 20px; */
}

.disclaimerCrossHair svg {
  width: 100%;
  /* height: auto; */
}
