* {
  font-family: "tomatogrotesk", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.bodyText {
  text-align: left;
  color: white;
  /* color: rgb(222, 206, 172); */
  margin-bottom: 20px;
  width: 100%;
}

.instagram {
  font-style: italic;
  color: white;
  /* color: rgb(222, 206, 172); */
}

.instaTextContainer {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
